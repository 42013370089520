
// intro screen functionality

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IntroBaseDialog } from './introbase'

export class IntroDialog extends IntroBaseDialog {
    constructor(props) {
        super(props);
        this.listselref = React.createRef();
    }
    
    renderIntro() {
        let nex = this.props.nexus;
        let stage = this.state.stage;
        if (!stage) {
            if (nex.new_player())
                stage = 1;
            else
                stage = 3;
        }
        if (stage == 1) {  // asking them if they played a MUD before
            let st = {};
            let btnYes = <Button variant="intro" onClick={() => this.setState({'stage' : 3 })}>{'Yes'}</Button>
            let btnNo = <Button variant="intro" onClick={() => this.setState({'stage' : 2 })}>{'No'}</Button>
            let line1 = (<div style={{color:'#ccc', margin:'15px'}}>{'Welcome to Nexus'}</div>);
            let line2 = (<div style={{fontSize:'1.6em', fontWeight:'bold', fontStyle:'italic', margin:'15px'}}>{'Have you played a MUD game before?'}</div>);
            let line3 = (<div style={{margin:'15px'}} ><a style={{fontSize:'0.8em', fontStyle:'italic', cursor:'pointer'}} onClick={()=>this.props.onClose()} >{'Skip this step'}</a></div>);
            return (<div style={st}>{line1}{line2}<div>{btnYes}<span style={{padding:'10px'}}></span>{btnNo}</div>{line3}</div>);
        }
        if (stage == 2) {
            let st = { color: 'white', 'maxWidth' : '750px', 'textAlign' : 'justify' };
            let introStyle = { fontSize:'1.4em', 'marginTop': '0.8em', 'marginBottom' : '0.8em', textAlign: 'left' };
            let lines = [];
            let line1 = (<div style={{fontSize:'1.6em', fontWeight:'bold', fontStyle:'italic', marginBottom:'15px'}}>{'What is a MUD game?'}</div>);
            lines.push(line1);
            lines.push((<div style={introStyle}>{'Immersive Text-Based Role-Playing'}</div>));
            lines.push((<div>{'A Multi-User Dungeon (MUD) game is a text-based online role-playing game that immerses players in a richly detailed virtual world. Players create characters and explore a vast, descriptive environment filled with quests, challenges, and intricate storylines.'}</div>));
            lines.push((<div style={introStyle}>{'Complex Systems and Engaging Gameplay'}</div>));
            lines.push((<div>{'MUDs feature complex systems for character development, combat, and crafting, providing a deep and engaging experience. They can be a solo journey or a collective adventure with other players. With their immersive storytelling and dynamic gameplay, MUDs offer a unique and timeless gaming experience that appeals to players who enjoy rich narrative and interactive gameplay.'}</div>));
            lines.push((<div style={introStyle}>{'Social Collaboration'}</div>));
            lines.push((<div>{'One of the key features of MUDs is their social aspect. Players can communicate with each other in real-time, forming alliances, joining guilds, and participating in community events. The collaborative nature of MUDs fosters a strong sense of camaraderie and teamwork.'}</div>));
            lines.push((<div style={introStyle}>{'Interactive Command Input'}</div>));
            lines.push((<div>{'In a MUD, the world is brought to life through text descriptions, and players input commands to interact with their surroundings, other characters, and various objects. This format encourages imagination and creativity, allowing players to envision the world and their actions within it. Typical activities include exploring diverse landscapes, battling monsters, solving puzzles, and completing quests.'}</div>));
            let btnProceed = <Button variant="intro" onClick={() => this.setState({'stage' : 3 })}>{'Start Playing!'}</Button>
            return (<div style={st}>{lines}<div style={{textAlign:'center','marginTop':'1em'}}>{btnProceed}</div></div>);
        }
        if (stage == 3) {
            let st = {};
            let line1 = (<div style={{color:'#ccc', margin:'15px'}}>{'Welcome to Nexus'}</div>);
            let line2 = (<div style={{fontSize:'1.6em', fontWeight:'bold', fontStyle:'italic', margin:'15px'}}>{'Select a Game World to Play'}</div>);
            let gameEls = [];
            for (let game of [ -1, -2 ])  {
                let gi = nex.game_settings(game);
                let url = gi.files_url();
                let logo = (<img src={url + '/images/logo.png'} alt='' width={260} />);
                logo = (<div style={{textAlign:'center'}}>{logo}</div>);
                let textLines = gi.introText();
                let text = [];
                let textSt = { 'paddingTop': '10px', 'paddingBottom' : '10px' };
                for (let line of textLines)
                    text.push((<div style={textSt}>{line}</div>));
                let playBtn = (<div style={{textAlign:'center'}}><Button variant="intro" style={{minWidth:'150px',fontWeight:'bold',color:'white',backgroundColor:gi.introButtonColor()}}>{'Play'}</Button></div>);
                let gameEl = (<div className='intro_game' onClick={()=>this.props.onGameSelect(game)}>{logo}{text}{playBtn}</div>);
                gameEls.push(gameEl);
            }
            gameEls = (<div className='intro_games'>{gameEls}</div>);
            let lineProceed = (<div style={{margin:'15px'}} ><a style={{fontStyle:'italic', cursor:'pointer'}} onClick={()=>this.props.onClose()} >{'Skip this step'}</a></div>);
            return (<div style={st}>{line1}{line2}{gameEls}{lineProceed}</div>);
        }
        return btnClose;
    }

    render() {
        let t = this;

        let content;
        let st = { 'textAlign' : 'center', 'position' : 'absolute', 'top' : '50%', 'left' : '50%', 'transform' : 'translate(-50%, -50%)' };
        if (t.props.logging_in) {
            content = (<div style={st}>{'Logging you into Nexus ...'}</div>);
        } else {
            let intro = t.renderIntro();
            content = (<div style={st}>{intro}</div>);
        }
        let title = 'Welcome to Nexus';

        let backdropStyle={backgroundSize: 'cover', backgroundColor: 'rgb(0,0,0)'};
        return (
            <Dialog
                className='intro_dialog'
                fullScreen
                open={this.props.open}
                onClose={() => t.props.setOpen(false)} 
                aria-labelledby="intro-dialog"
                PaperProps={{style: {backgroundColor: 'rgba(0,0,0,0)', backgroundImage: 'none'} }}
                disableEscapeKeyDown={true}
                BackdropProps={{style:backdropStyle}}>
            <DialogTitle id="intro-dialog">{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            </Dialog>
        );
    }
}
