import React from 'react'

import { Util } from '../base/util.js'

export class GameSelectorBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = { games: [], loaded: false };
    }

    componentDidMount() {
        this._mounted = true;
        this.loadGameList();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentDidUpdate() {
        if ((!this.props.loggedin) && this.state.games.length) {
            this.setState({games:[], loaded: false});
        }
        if (this.props.loggedin && (!this.state.loaded))
            this.loadGameList();
    }

    loadGameList() {
        let chars = this.props.chars;
        if (!chars.aid()) return;

        chars.games().then((lst) => {
            if (!this._mounted) return;  // no longer mounted
            this.setState({games : lst, loaded: true }, () => {
                this.onGameListLoaded();
            });
        });
    }

    onGameListLoaded() {
    }

    onGameSelect(gameID, info) {
        this.props.onSelect (gameID, info);
    }

    validateGame(name, server, port, gameID=null) {
        if ((!name) || (name.length < 3)) return 'Please specify a valid game name.';
        if ((!server) || (server.length < 3)) return 'Please specify a valid server name.';
        if ((port <= 0) || (port >= 65536) || isNaN(port)) return 'The port must be a positive number in the 1-65535 range.';

        let nex = this.props.nexus;
        let gameIDs = nex.gameIDs();
        for (let idx = 0; idx < gameIDs.length; ++idx) {
            let gid = gameIDs[idx];
            let gi = nex.game_settings (gid, null);
            if (gi.game_name().toLowerCase() === name.toLowerCase()) return 'Please use a name that does not refer to one of Iron Realms games.';
        }

        // If there another matching game?
        let gamelist = this.state.games;
        for (let idx = 0; idx < gamelist.length; ++idx) {
            let g = gamelist[idx];
            let gid = parseInt(g['gid']);
            if (gid === gameID) continue;
            if (g['name'] === name) return 'Your list already contains a game called ' + name;
            if ((g['server'] == server) && (g['port'] == port)) return 'The game ' + g['name'] + ' on your list already points to ' + g['server'] + ':' + g['port'] + '.';
        }

        if (!gameID) return null;
        let g = this.getGame(gameID);
        if (!g) return null;  // ??
        if ((name == g['name']) && (server == g['server']) && (port == g['port'])) return 'No change requested.';

    }

    doAddGame(name, server, port) {
        name = name.trim();
        server = server.trim();
        port = parseInt(port);
        let msg = this.validateGame(name, server, port);

        let nex = this.props.nexus;
        if (msg) { nex.platform().alert('Error adding game', msg); return; }

        let chars = this.props.chars;
        chars.game_add(name, server, port).then(() => this.loadGameList())
       .catch((err) => nex.platform().alert('Error adding game', String(err)));
    }
    
    doUpdateGame(gameID, name, server, port) {
        name = name.trim();
        server = server.trim();
        port = parseInt(port);
        let msg = this.validateGame(name, server, port, gameID);

        let nex = this.props.nexus;
        if (msg) { nex.platform().alert('Error updating game', msg); return; }
        
        let chars = this.props.chars;
        chars.game_change(gameID, name, server, port).then(() => this.loadGameList())
       .catch((err) => nex.platform().alert('Error adding game', String(err)));
    }

    doRemoveGame(gameID) {
        let nex = this.props.nexus;

        let chars = this.props.chars;
        chars.game_remove(gameID).then(() => this.loadGameList())
       .catch((err) => nex.platform().alert('Error removing game', String(err)));
    }

    onGameAdd() {
        let nex = this.props.nexus;
        if (!this.props.loggedin) {
            nex.platform().alert('Cannot add game', 'Adding third-party games requires you to be logged into Nexus.');
            return;
        }
        nex.ui().layout().showGameAdd('', '', '', false, (name, server, port) => this.doAddGame(name, server, port));
    }
    
    onGameUpdate(gameID) {
        let g = this.getGame(gameID);
        if (!g) return;

        let nex = this.props.nexus;
        nex.ui().layout().showGameAdd(g['name'], g['server'], g['port'], true, (name, server, port) => this.doUpdateGame(gameID, name, server, port));
    }

    onGameRemove(gameID) {
        let g = this.getGame(gameID);
        if (!g) return;

        let nex = this.props.nexus;
        nex.platform().confirm('Really Remove?', 'Do you really want to remove ' + Util.ucfirst(g['name']) + ' from your Nexus account? This will also remove the registered characters.', () => {
            this.doRemoveGame(gameID);
        });
    }

    getGame(gameID) {
        let gamelist = this.state.games;
        for (let idx = 0; idx < gamelist.length; ++idx) {
            let g = gamelist[idx];
            let gid = parseInt(g['gid']);
            if (gid === gameID) return g;
        }
        return null;
    }
    
    renderContent() {
        let nex = this.props.nexus;
        let curGID = nex.active_game_id();
        let content = [];
        let games = nex.gameIDs(false);
        content.push (this.renderHeader('Select Game World'));
        for (let idx = 0; idx < games.length; ++idx) {
            let gi = nex.game_settings (games[idx], null);
            let cur = (curGID === games[idx]);
            content.push (this.renderGamePicker (games[idx], gi.game_short(), null, null, cur, false));
        }

        games = nex.legacyGameIDs();
        for (let idx = 0; idx < games.length; ++idx) {
            let gi = nex.game_settings (games[idx], null);
            let cur = (curGID === games[idx]);
            content.push (this.renderGamePicker (games[idx], gi.game_short(), 'Legacy Game', null, cur, false));
        }

        let gamelist = this.state.games;
        for (let idx = 0; idx < gamelist.length; ++idx) {
            let g = gamelist[idx];
            let gid = parseInt(g['gid']);
            let cur = (curGID == gid);
            let caption = g['name'];
            content.push (this.renderGamePicker (gid, caption, 'Third-Party Game', g, cur, true));
        }
        content.push (this.renderButtonAdd());
        return content;
    }

}
