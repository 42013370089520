import React from 'react'

import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GameSelectorBase } from './gameselector_base'

export class GameSelector extends GameSelectorBase {

    onGameListLoaded() {
        // Need to re-select the current game too, in case it got changed
        // This is only done in the web version, not on mobile.
        let nex = this.props.nexus;
        let gameID = nex.active_game_id();
        if (gameID > 0) {
            let g = this.getGame(gameID);
            this.onGameSelect(gameID, g);
        }
    }

    renderGamePicker(gameID, caption, subtitle, info, cur, deletion) {
        let buttons;
        if (deletion) {
            let iconstyle = {height:'22px', width:'22px', paddingLeft:'5px'};
            let btn_settings = (<FontAwesomeIcon className='game_rename' style={iconstyle} icon={['fad', 'pen']} aria-label="Update" onClick={() => this.onGameUpdate(gameID)} />);
            btn_settings = (<Tooltip title={'Edit Settings'}><span>{btn_settings}</span></Tooltip>);
            let btn_delete = (<FontAwesomeIcon className='game_delete' style={iconstyle} icon={['fad', 'trash-can']} aria-label="Remove" onClick={() => this.onGameRemove(gameID)} />);
            btn_delete = (<Tooltip title={'Delete'}><span>{btn_delete}</span></Tooltip>);
            buttons = (<div>{btn_settings}{btn_delete}</div>);
        }
        
        let expanded = null;
        if (cur) expanded = (<div className='gamesel_active_info'>{caption + ' is the current game.'}</div>);

        let titlesStyle = { 'display' : 'flex', 'flex-direction' : 'column', 'flex' : 1 };
        caption = (<div key='gamesel_name' className='gamesel_name'>{caption}</div>);        
        if (subtitle) subtitle = (<div key='gamesel_subtitle' className='gamesel_subtitle'>{subtitle}</div>);
        let titles = (<div style={titlesStyle}>{caption}{subtitle}{expanded}</div>);
            
        let contentStyle = { 'display' : 'flex', 'flex-direction' : 'row' };
        let content = (<div key='gamesel_entry' style={contentStyle}>{titles}{buttons}</div>);

        let classes = 'gamesel_entry';
        if (cur) classes += ' current';

        return ((<div key={'gamesel_'+gameID} className={classes} onClick={()=>this.onGameSelect(gameID, info)}>{content}</div>));
    }

    renderButtonAdd() {
        let btn_plus = (<FontAwesomeIcon icon={['fad', 'square-plus']} style={{height:'32px', width:'32px', paddingRight:'10px', verticalAlign: 'bottom'}} />);
        let res = (<div key='gamesel_name' className='gamesel_name' style={{textTransform:'none'}}>{btn_plus}{'Add Game'}</div>);
        return ((<div key={'game_add'} className='gamesel_entry' onClick={()=>this.onGameAdd()}>{res}</div>));
    }

    renderHeader(text) {
        return (<div key={'select'+text} className='gamesel_header'>{text}</div>);
    }

    renderSpace() {
        return (<div key='space' style={{height:'50px'}}>&nbsp;</div>);
    }

    render() {
        let content = this.renderContent();
        let res = (<div className='gameSelector' style={this.props.style || {}}>{content}</div>);
        return res;
    }
}
 
